<template>
  <div v-if="practice && questionOrder" class="container">
    <h1>{{ practice.exam.title }}</h1>
    <el-row>
      <el-col :span="22">
        <div style="display: flex; flex-wrap: wrap">
          <div
            :style="`border: ${questionOrder === index + 1 ? '#42a16a' : (hasAnswer(question) ? '#42a16a60' : '#ddd')} 4px solid;`"
            class="questionOrderLink"
            @click="() => enterQuestion(index + 1)"
            :key="question.order"
            v-for="(question, index) in practice.exam.questions"
          >
            <b>{{ index + 1 }}</b>
          </div>
        </div>
      </el-col>
      <el-col :span="2">
        <b v-if="accumulationSecond">
          {{
            `${
              practice.has_timing ? this.getRestTime(accumulationSecond) : "--"
            }`
          }}
        </b>
      </el-col>
    </el-row>
    <div class="row">
      <div class="col-sm-6" style="text-align: center">
        <el-button
          @click="() => enterQuestion(questionOrder - 1)"
          :disabled="questionOrder === 1"
          style="width: 90%"
          type="primary"
        >
          Previous
        </el-button>
      </div>
      <div
        v-if="questionOrder === practice.exam.questions.length"
        class="col-sm-6"
        style="text-align: center"
      >
        <el-button
          @click="() => submitPractice(false)"
          style="width: 90%; background: #19a1fb"
          type="primary"
        >
          Submit
        </el-button>
      </div>
      <div v-else class="col-sm-6" style="text-align: center">
        <el-button
          @click="() => enterQuestion(questionOrder + 1)"
          :disabled="
            questionOrder ===practice.exam.questions.length
          "
          style="width: 90%"
          type="primary"
        >
          Next
        </el-button>
      </div>
    </div>
    <el-row type="flex" justify="space-between">
      <el-col v-if="!isMultiple && hasPassage" class="border" :span="11">
        <ViewPassage
          :passage="questionAdapter(getCurrentQuestion()).question.passage"
          :type="fullPractice.template_type === 'ORIGINAL' ? questionAdapter(getCurrentQuestion()).question_type : fullPractice.template_type"
          :section="questionAdapter(getCurrentQuestion()).question.subject.name"
        />
      </el-col>
      <el-col class="border" :span="isMultiple || !hasPassage ? 24 : 11">
        <div class="ivy-quesitons">
          <QuestionPractice
            :answers="answers"
            :question="questionAdapter(getCurrentQuestion())"
            :defaultQuestionTemplate="fullPractice.template_type"
            @setAnswer="setAnswer"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import questionAdapter from "@/components/practices/QuestionAdapter";
import fullPracticesApi from "@/apis/fullPractices";
import QuestionPractice from "@/components/practices/QuestionPractice.vue";
import ViewPassage from "@/components/tests/ViewPassage.vue";
import Practice from "@/views/practices/Practice";
import FullPractice from "@/views/practices/FullPractice";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { QuestionPractice, ViewPassage },
  data() {
    return {
      timerInterval: null,
      accumulationSecond: 0,
      fullPractice: null,
      practice: null,
      userExam: null,
      answers: [],
      questionOrder: null
    };
  },
  computed: {
    pageTitle() {
      return this.fullPractice ? this.fullPractice.exam.title : "";
    },
    questionAdapter() {
      return questionAdapter;
    },
    limitTime() {
      return this.practice
        ? this.practice.timing_minutes * 60 +
            Number(this.practice.timing_seconds)
        : Number.MAX_SAFE_INTEGER;
    },
    localStoragePracticeKey() {
      return `PRACTICE_${this.$route.params.fullPracticeId}_SECTION_${this.$route.params.sectionOrder}_TIMER`;
    },
    isMultiple() {
      return (
        questionAdapter(this.getCurrentQuestion()).question.type === "multiple"
      );
    },
    isMathCalculator() {
      return questionAdapter(this.getCurrentQuestion()).question.type === "math";
    },
    otherStatus() {
      return this.userExam.other_status ? this.userExam.other_status[0] : {};
    },
    hasPassage() {
      return questionAdapter(this.getCurrentQuestion()).question.passage;
    }
  },
  async created() {
    this.questionOrder = Number(this.$route.params.questionOrder);
    await this.fetchUserExam();
    await this.fetchFullPractice();
    if (!localStorage.getItem(this.localStoragePracticeKey)) {
      const otherStatus = this.userExam.other_status;
      localStorage.setItem(
        this.localStoragePracticeKey,
        otherStatus
          ? otherStatus[0][
            `setion${this.$route.params.sectionOrder}progress_time`
          ]
          : 0
      );
    }
    this.accumulationSecond =
      Number(localStorage.getItem(this.localStoragePracticeKey)) || 0;

    this.timerInterval = setInterval(() => {
      const accumulationSecond =
        Number(localStorage.getItem(this.localStoragePracticeKey)) || 0;
      this.accumulationSecond = accumulationSecond;
      if (
        this.limitTime <= this.accumulationSecond &&
        this.practice.has_timing
      ) {
        clearInterval(this.timerInterval);
        this.submitPractice(true);
      } else {
        localStorage.setItem(
          this.localStoragePracticeKey,
          accumulationSecond + 1
        );
      }
    }, 1000);

    this.getAnswer();
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  methods: {
    async fetchFullPractice() {
      const { compose_practice } = await fullPracticesApi.fetchFullPractice(
        this.$route.params.fullPracticeId
      );
      this.fullPractice = new FullPractice(compose_practice);
      const currentSection = this.fullPractice.compose_practice_practices[
        Number(this.$route.params.sectionOrder - 1)
      ];
      this.practice = new Practice(currentSection.practice);
    },
    async fetchUserExam() {
      const { user_exam } = await fullPracticesApi.takeFullPracticeExam(
        this.$route.params.fullPracticeId,
        { user_exam_id: this.$route.params.userExamId }
      );
      this.userExam = user_exam;
    },
    hasAnswer(question) {
      return (
        this.userExam.exam_answers.find(
          ({ exam_question_id }) => exam_question_id === question.id
        ).answers.length !== 0
      );
    },
    getRestTime(accumulationSecond) {
      const restSecond = this.limitTime - accumulationSecond;
      const minutes = String(Math.floor(restSecond / 60)).padStart(2, "0");
      const seconds = String(Math.floor(restSecond % 60)).padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    getCurrentQuestion() {
      if (this.practice) {
        return this.practice.exam.questions.find(
          question =>
            question.order === Number(this.questionOrder)
        );
      }
      return null;
    },
    getAnswer() {
      let answersMap = {};
      this.practice.exam.questions.forEach(({ id }) => {
        answersMap[id] = this.userExam.exam_answers.find(
          ({ exam_question_id }) =>
            exam_question_id === this.getCurrentQuestion().id
        );
      });
      this.answers = answersMap[this.getCurrentQuestion().id].answers;
    },
    setAnswer(newAnswer) {
      this.answers = newAnswer;
      console.log(newAnswer);
    },
    async enterQuestion(questionOrder) {
      let answers = this.answers;
      if (this.isMathCalculator) {
        const mathAnswer = this.answers[0];
        if (mathAnswer) {
          answers = [];
          let answerStr = [];
          for(let i = 0; i < 4;i++){
            console.log(mathAnswer[i]);
            answerStr.push(mathAnswer[i] ? mathAnswer[i] : " ");
          }
          answers = [`${answerStr[0]}${answerStr[1]}${answerStr[2]}${answerStr[3]}`];
        } else {
          answers = [];
        }
      }
      console.log(answers);
      try {
        clearInterval(this.timerInterval);
        localStorage.removeItem(this.localStoragePracticeKey);
        await fullPracticesApi.postFullPracticeAnswer(
          this.$route.params.userExamId,
          {
            exam_question_id: this.getCurrentQuestion().id,
            is_finished: 0,
            answers: answers,
            other_status: [
              {
                ...this.otherStatus,
                [`setion${this.$route.params.sectionOrder}progress_time`]: this
                  .accumulationSecond,
                currentSection: this.$route.params.sectionOrder,
                isBreakTime: 0
              }
            ],
            progress_remaining: this.limitTime - this.accumulationSecond
          }
        );
        this.$router.replace({
          name: "PracticeSection",
          params: {
            fullPracticeId: this.fullPractice.id,
            userExamId: this.$route.params.userExamId,
            sectionOrder: this.$route.params.sectionOrder,
            questionOrder
          }
        });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async submitPractice(ignoreCheck) {
      if (!ignoreCheck) {
        try {
          await this.$confirm(
            "Are you sure you want to submit your answers?",
            this.$t("message.notice"),
            {
              confirmButtonText: this.$t("message.continue"),
              cancelButtonText: this.$t("message.cancel"),
              type: "warning"
            }
          );
        } catch (e) {
          return false;
        }
      }

      try {
        clearInterval(this.timerInterval);
        localStorage.removeItem(this.localStoragePracticeKey);

        if (
          Number(this.$route.params.sectionOrder) <
          this.fullPractice.compose_practice_practices.length
        ) {
          await this.enterToNextPage(
            0,
            `/fullPractice/${this.fullPractice.id}/userExam/${this.userExam.id}/BreakTime`
          );
        } else {
          let nextPagePath = `/fullPractice/${this.fullPractice.id}/userExam/${this.userExam.id}/result`;
          if (this.fullPractice.scoring_type === "SAT") {
            nextPagePath = `/sat/result?user_exam_id=${this.userExam.id}`;
          } else if (this.fullPractice.scoring_type === "ACT") {
            nextPagePath = `/act/result?user_exam_id=${this.userExam.id}`;
          } else if (this.fullPractice.scoring_type === "TOEFL") {
            nextPagePath = `/toefl/transcript?id=${this.userExam.id}`;
          }
          await this.enterToNextPage(1, nextPagePath);
        }
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async enterToNextPage(isFinished, nextPagePath) {
      let answers = this.answers;
      if (this.isMathCalculator) {
        const mathAnswer = this.answers[0];
        if (mathAnswer) {
          answers = [];
          let answerStr = [];
          for(let i = 0; i < 4;i++){
            answerStr.push(mathAnswer[i] ? mathAnswer[i] : " ");
          }
          answers = [`${answerStr[0]}${answerStr[1]}${answerStr[2]}${answerStr[3]}`];
        } else {
          answers = [];
        }
      }
      await fullPracticesApi.postFullPracticeAnswer(
        this.$route.params.userExamId,
        {
          exam_question_id: this.getCurrentQuestion().id,
          progress_remaining: this.limitTime - this.accumulationSecond,
          other_status: [
            {
              ...this.otherStatus,
              [`setion${this.$route.params.sectionOrder}progress_time`]: this
                .accumulationSecond,
              currentSection: Number(this.$route.params.sectionOrder),
              isBreakTime: 1
            }
          ],
          is_finished: isFinished,
          answers: answers
        }
      );
      this.$router.replace({
        path: nextPagePath
      });
    }
  }
};
</script>

<style scope>
.questionOrderLink {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  border: #ddd 4px solid;
  margin: 0px 8px 8px 0px;
}

.questionOrderLink:hover {
  border: #42a16a 4px solid;
}

.border {
  border: 1px solid #ccc;
  padding: 2%;
  overflow: auto;
  margin: 0 15px;
  max-height: 600px;
}
</style>
